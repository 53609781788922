import React, { useEffect, useState } from "react";
import "./DashboardMain.css";
import DashHeader from "../DashHeader/DashHeader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utils/API";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utils/Matrix_Contract";
import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import Timer from "../Timer/Timer";

const DashboardMain = () => {
  const user = useSelector((state) => state.Auth.userId);
  const sec = useSelector((state) => state.Auth.jwtToken);
  const ip = useSelector((state) => state.Auth.ipAddress);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let URL = window.location.origin;
  const LeftCopyAddressHandler = () => {
    const text = `${URL}/Register?userid=${user}&position=Left`;
    //  `${URL}/registration?userid=${user}`
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const RightCopyAddressHandler = () => {
    const text = `${URL}/Register?userid=${user}&position=Right`;

    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const [dash, setdataArray] = useState([]);

  const [walletAddress, setwalletAddress] = useState("ConnectWallet");

  const Dashboard_API = async () => {
    try {
      let acc = await loadWeb3();
      setwalletAddress(acc);
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${sec}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];

      console.log("Dash res-->", responce);

      setdataArray(responce);
      if (responce.CurrentPackage > 0) {
        dispatch(updateStatus({ status: "Active" }));
      } else {
        dispatch(updateStatus({ status: "In-Active" }));
      }
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };
  const AddressCopyAddressHandler = () => {
    const text = walletAddress;

    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  useEffect(() => {
    Dashboard_API();
  }, []);
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row">
            <div className="col-xl-9 col-lg-12">
              <div className="row g-3 mx-0">
                <div className="col-lg-12">
                  <div className="nftHeading">
                    <h2 className="fs-18 pt-2">DASHBOARD</h2>
                    {dash?.CurrentPackage > 0 && (
                      <div className="text-center rounded-3 timerdiv">
                        <Timer endTime={dash?.Boostertimer_status} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pt-1">
                  <div className="popular_nfts mb22">
                    <div className="nftTimeItem positioncss">
                      <h1 id="leftTime" />
                      <p>Welcome to the world of Ragscoin.community</p>
                      {/* <p>
                        Click on the button below and share it with your people.
                      </p> */}
                      <div className="TotalEarnChatMain mt-3">
                        <div className="priceChart">
                          <span className="priceChart_text">
                            $ {dash?.TotalInvest}
                          </span>
                          <div className="slice">
                            <div className="bar" />
                            <div className="fill" />
                          </div>
                        </div>
                      </div>
                      <div className="d-sm-flex align-sm-items-center gap-3">
                        <button
                          type="button"
                          className="btn button btnhome w-50 leffttf"
                          onClick={LeftCopyAddressHandler}
                        >
                          Copy Left Referral Link
                        </button>
                        <button
                          type="button"
                          className="btn button btnhome w-50"
                          onClick={RightCopyAddressHandler}
                        >
                          Copy Right Referral Link
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-1 resSeb">
                  <div className="popular_nfts ">
                    <div className="nftTimeItem reassa positioncss">
                      <div className="d-sm-flex align-items-center justify-content-between gy-3">
                        <div className="">
                          <h1 id="leftTime2" />
                          <p className="bgcolor mb-2">
                            Working 4x Capping ($ {dash.MaxIncome})
                          </p>
                          <div className="TotalEarnChatMain mt-3">
                            <div className="priceChart">
                              <span className="priceChart_text">
                                $ {dash.EarnAmount}
                              </span>
                              <div className="slice">
                                <div className="bar" />
                                <div className="fill" />
                              </div>
                            </div>
                          </div>
                          <h2>Status :{dash.EarnPer} %</h2>
                        </div>
                        <div className="">
                          <h1 id="leftTime2" />
                          <p className="bgcolor mb-2">
                            Non-working 2x Capping ($ {dash.MaxIncomeroi})
                          </p>
                          <div className="TotalEarnChatMain mt-3">
                            <div className="priceChart">
                              <span className="priceChart_text">
                                $ {dash.EarnAmountroi}
                              </span>
                              <div className="slice">
                                <div className="bar" />
                                <div className="fill" />
                              </div>
                            </div>
                          </div>
                          <h2>Status :{dash.EarnPerroi} %</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt30hsff dmt-80 mt-3 g-3 mx-0">
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/image/icon/revenue-icon-20.jpg"
                            alt=""
                          />
                        </span>
                        ROI Per Minute
                      </h2>
                      <p className="text-end">$ {dash?.RoiPerMinute} </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/image/icon/revenue-icon-20.jpg"
                            alt=""
                          />
                        </span>
                        Direct Income{" "}
                      </h2>
                      <p className="text-end">$ {dash?.Direct_Income} </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/image/icon/profits.png"
                            alt=""
                          />
                        </span>{" "}
                        ROI Income{" "}
                      </h2>
                      <p className="text-end">$ {dash?.Roi_Income} </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100 p-2"
                            src="./assets/image/icon/income.png"
                            alt=""
                          />
                        </span>
                        ROI Level Income
                      </h2>
                      <p className="text-end">$ {dash?.Roi_Level_Income} </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/image/icon/matching.webp"
                            alt=""
                          />
                        </span>
                        Total Matching Income
                      </h2>
                      <p className="text-end">$ {dash?.TotalBinary}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/image/icon/matching.webp"
                            alt=""
                          />
                        </span>
                        Remain Matching Income
                      </h2>
                      <p className="text-end">$ {dash?.RemainBinary}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/image/icon/matching.webp"
                            alt=""
                          />
                        </span>
                        Paid Matching Income
                      </h2>
                      <p className="text-end">$ {dash?.PaidBinary}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/image/newimage/Net-Withdrawal-Balance.svg"
                            alt=""
                          />
                        </span>
                        Total Income
                      </h2>
                      <p className="text-end">$ {dash.Total_Income}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/image/icon/wallet.png"
                            alt=""
                          />
                        </span>
                        Total Withdrawal
                      </h2>
                      <p className="text-end">$ {dash.WithDrawal_Amount}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12 col-sm-6 col-md-4">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src="./assets/image/icon/913417.png"
                            style={{ filter: "invert(1)" }}
                            alt=""
                          />
                        </span>
                        Net Balance
                      </h2>
                      <p className="text-end">$ {dash.Net_Balance}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-8 col-md-12 mx-auto">
              <div className="row gy-2 mx-0">
                {/* <div className="col-lg-4" /> */}
                <div className="col-xl-12  col-md-8 mx-auto">
                  <div className="Img_profile">
                    <center>
                      <img src="./assets/image/profile.png" className="img_p" />
                    </center>
                  </div>
                  <div className="zackItem">
                    <div className="profileSection">
                      <h2>User Id:</h2>
                      <p className="mb-0">{user}</p>
                      <div className="SellersItem shadow-none border-0">
                        <div className="SellersText">
                          <h2>Wallet Address:</h2>
                          <p className="text-center ksjhajq" id="walletAddress">
                            {`${walletAddress?.substring(
                              0,
                              6
                            )}...${walletAddress?.substring(
                              walletAddress?.length - 2
                            )}`}
                            <button
                              type="button"
                              className="btn button btn_hxn w-auto"
                            >
                              Copy Address
                            </button>
                          </p>
                        </div>
                      </div>
                      <div className="SellersItem shadow-none border-0">
                        <div className="SellersText">
                          <h2>Your IP Address:</h2>
                          <p className="mb-0">{ip}</p>
                          <br></br>
                        </div>
                      </div>
                      <div className="">
                        <h2 className="timetextimg">
                          <span className="cole">
                            Booster : {dash.BoosterStatus}
                          </span>
                          <br></br>
                          <br></br>
                        </h2>
                      </div>
                      <div className="">
                        <h2 className="timetextimg">
                          <span className="cole">Rank : {dash.Rankname} </span>
                          <br></br>
                          <br></br>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mt30hsff2 mb50 g-3 mx-0">
                <div className="col-lg-6">
                  <div className="hotbidsItem maincard p-3 rounded-3 cardscards">
                    <div className="row mx-0 gy-2 mx-0">
                      <div className="col-12">
                        <div className="card mycard">
                          <div className="card-body popular_nfts ">
                            <div className="d-sm-flex align-items-center justify-content-between  nftteam2nd ">
                              <div className="fs-16 fw-bold text-white text-uppercase">
                                My Package
                                <br />
                                <div className="fs-14 fw-bold text-white text-uppercase my-2">
                                  Current Package-: $ {dash?.CurrentPackage}
                                </div>
                                <div className="fs-14 fw-bold text-white text-uppercase">
                                  gross Package-: $ {dash.TotalInvest}
                                </div>
                              </div>
                              <div className="TotalEarnChatMain" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card mycard2">
                          <div className="card-body ">
                            <div className="card-heading text-white fs-6 fw-bold pb-3 text-uppercase">
                              Team Size
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  Left{" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/download.png"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  {" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon2.png"
                                      alt=""
                                    />
                                  </span>
                                  Right
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total :{dash?.TotalLeftTeam}{" "}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : {dash?.TotalRightTeam}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Active : {dash?.TotalLeftActiveTeam}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Active : {dash?.TotalRightActiveTeam}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card mycard2">
                          <div className="card-body ">
                            <div className="card-heading text-white fs-6 fw-bold pb-3 text-uppercase">
                              Team Business
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  Left{" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/download.png"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  {" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon2.png"
                                      alt=""
                                    />
                                  </span>
                                  Right
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : $ {dash.totalLeftTeamBusiness}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : $ {dash.totalRightTeamBusiness}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Today : $ {dash?.todayLeftTeamBusiness}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Today : $ {dash?.todayRightTeamBusiness}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card mycard2">
                          <div className="card-body ">
                            <div className="card-heading text-white fs-6 fw-bold pb-3 text-uppercase">
                              My Referral
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  Left{" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/download.png"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                                  {" "}
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon2.png"
                                      alt=""
                                    />
                                  </span>
                                  Right
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : {dash?.TotalLeftReferral}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Total : {dash?.TotalRightReferral}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Active : {dash?.TotalLeftActiveReferral}
                                </div>
                                <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                                  <span className="d-flex align-items-center justify-content-center">
                                    <img
                                      src="./assets/image/newimage/icon1.png"
                                      alt=""
                                    />
                                  </span>
                                  Active : {dash?.TotalRightActiveReferral}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card myteamcard">
                    <div className="card-header py-3 bgmain">
                      INCOME SUMMARY
                    </div>
                    <div className="row mx-0">
                      <div className="col-12">
                        <ul className="p-0 m-0 listcricle">
                          <li className="d-flex my-3 p-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash?.Direct_Income}{" "}
                              </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Direct Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash?.Binary_Income}
                              </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress one"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Matching Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">$ {dash?.Roi_Income} </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    ROI Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash?.Roi_Level_Income}
                              </div>
                              <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg>
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-9">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    ROI Level Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMain;
