import React, { useState, useEffect } from "react";

const Timer = ({ endTime }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {

    if (endTime === "Booster Achieved") {
      return;
    }


    const endDate = new Date(endTime);

    const calculateTImeDifference = () => {
      const currentDate = new Date();

      const timeDifference = Math.max(
        0,
        Math.floor(endDate - currentDate) / 1000
      );

      setSeconds(timeDifference);
    };



    calculateTImeDifference();

    const interval = setInterval(calculateTImeDifference, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [endTime]);

  const formatTime = () => {
    const remainingDays = Math.floor(seconds / (24 * 60 * 60));
    const remainingHours = Math.floor((seconds % (24 * 60 * 60)) / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return `${String(remainingDays).padStart(2, "0")}d ${String(
      remainingHours
    ).padStart(2, "0")}h ${String(remainingMinutes).padStart(2, "0")}m ${String(
      remainingSeconds
    ).padStart(2, "0")}s`;
  };


  return (
    <div>
      {endTime === "Booster Achieved" ? (
        <p style={{ color: "rgb(229 184 11)", padding: "8px 0px", fontSize: "30px" }}>
          Booster Achieved
        </p>
      ) : (

        <p style={{ color: "rgb(229 184 11)", padding: "8px 0px", fontSize: "30px" }}>
          Booster Time: {formatTime()}
        </p>)}
    </div>
  );
};

export default Timer;
